import { UserSignUpMutation as UserSignUpMutationOutput, UserSignUpMutationVariables } from '@global/utils/remote-graphql-types';
import { TypedDocumentNode } from '@web/data/apollo-client';
import gql from 'graphql-tag';

export const UserSignUpMutation: TypedDocumentNode<UserSignUpMutationOutput, UserSignUpMutationVariables> = gql`
  mutation UserSignUp($objects: [user_insert_input!]!, $update_columns: [user_update_column!]!) {
    insert_user(objects: $objects, on_conflict: { constraint: User_id_key, update_columns: $update_columns }) {
      returning {
        id
        first_name
        gender
      }
    }
  }
`;
