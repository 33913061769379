import { PageProps } from '@lp-src/utils/local-types';
import { Gender } from '@global/utils/domain/entities';
import { Mutation_Root } from '@global/utils/remote-graphql-types';
import { UserSignUpMutation } from '@lp-root/src/data/graphql/hasura/user.mutation.hasura';
import { buildChatfuelBroadcastRequest } from '@lp-root/src/data/request-utils/redirect.request';
import { Col, Grid, InputLabel, Row, Separator } from '@web/atomic';
import { RadioField } from '@web/atomic/legacy/atm.radio';
import { GatsbyButton } from '@components/atm.button/button.component';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { useMutationCustom } from '@web/data/use-mutation-custom.hook';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { UserIdDataSource } from '@web/data/user-id.datasource';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import React, { useCallback } from 'react';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { SEO } from '../../components/legacy/mol.seo/seo.component';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';

interface UserSignUpPageQueryParams {
  gender: Gender;
  name: string;
}

interface SignUpFormData {
  first_name: string;
  gender: Gender;
}

const UserSignUpPage: React.FunctionComponent<PageProps> = (props) => {
  const queryParams = useQueryParams<UserSignUpPageQueryParams>();
  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Cadastro',
          description: 'Ferramenta para acompanhamento do sono',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12} sm={12} md={7} lg={7}>
            <SignUpForm defaultFirstName={queryParams.name?.split(' ')?.[0]} defaultGender={queryParams.gender} />
          </Col>
        </Row>
      </Grid>
    </WebviewLayout>
  );
};

export default UserSignUpPage;

export const query = graphql`
  query UserSignUpPage {
    site {
      ...SiteUrl
    }
  }
`;
// ------------------------------------------------------------------------------------------------------

interface SignUpFormProps {
  defaultFirstName?: string;
  defaultGender?: Gender;
}

const SignUpForm: React.FunctionComponent<SignUpFormProps> = (props) => {
  const [submitUserSignUpItem, submitLoading] = useSignUpMutation();
  const handleSubmit = useCallback(
    async (formData: FormData<SignUpFormData>) => {
      if (Object.keys(formData.error).length !== 0) return;
      console.log('DIARY_DEBUG: cadastro.tsx ~ line 76 ~ formData');
      const userId = await UserIdDataSource.getId();
      const userVariable = {
        id: userId,
        ...formData.data,
      };
      await submitUserSignUpItem({ variables: { objects: userVariable, update_columns: Object.keys(userVariable) } });
    },
    [submitUserSignUpItem]
  );

  const name: keyof SignUpFormData = 'first_name';
  const gender: keyof SignUpFormData = 'gender';

  return (
    <Form onSubmit={handleSubmit}>
      <Row mb center="xs">
        <Col xs>
          <Form.Field key={props.defaultFirstName} initialValue={props.defaultFirstName} name={name} validators={[Validators.Required()]}>
            <InputLabel>Como eu posso te chamar?</InputLabel>
            <TextField />
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      <Row mb center="xs">
        <Col xs>
          <Form.Field
            key={props.defaultGender}
            initialValue={props.defaultGender}
            name={gender}
            label="Qual é seu gênero?"
            validators={[Validators.Required()]}
          >
            <Separator />
            <RadioField id={Gender.Female}>
              <span role="img" aria-label="mulher">
                👩
              </span>{' '}
              Feminino
            </RadioField>
            <RadioField id={Gender.Male}>
              <span role="img" aria-label="homem">
                👨
              </span>{' '}
              Masculino
            </RadioField>
            <RadioField id={Gender.Other}>
              <span role="img" aria-label="outro gênero">
                👤
              </span>{' '}
              Outro
            </RadioField>
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      <Row mb>
        <Col xs={12} sm={12} md={4} lg={4}>
          <GatsbyButton type="submit" kind="primary" loading={submitLoading} expanded>
            Enviar resposta
          </GatsbyButton>
          <Separator />
        </Col>
      </Row>
    </Form>
  );
};

// ------------------------------------------------------------------------------------------------------
// TODO: REFACTOR - move code below somewhere else

const useSignUpMutation: () => [any, boolean] = () => {
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const [redirect, { loading: redirectLoading }] = usePostUsingMessenger<ChatfuelBroadcastRequest>({
    onSuccess: close,
    url: PostUrl.RedirectUser,
  });
  const handleSuccess = useCallback(
    (data: Mutation_Root) => {
      const { id, first_name, gender } = data.insert_user.returning[0];
      return redirect(
        buildChatfuelBroadcastRequest(id, 'get_user_info_success', {
          gender,
          'first name': first_name,
          'last name': '',
          timezone: new Date().getTimezoneOffset() / -60,
        })
      );
    },
    [redirect]
  );

  const [submitUserInfo, { loading: submitLoading }] = useMutationCustom(UserSignUpMutation, {
    onCompleted: handleSuccess,
  });

  return [submitUserInfo, submitLoading || redirectLoading || waitCloseLoading];
};
